<template>
	<div v-if="provider.value">
		<BackButton :route="{ name: 'md-staff-providers', label: 'Providers' }" />

		<div class="my-5 md:flex md:items-center md:justify-between">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Provider:
					<span class="text-sc-green">{{ provider.value.name || '' }}</span>
				</h2>
			</div>
			<div v-if="draft && page" class="flex flex-shrink-0 mt-2 ml-4 gap-x-5">
				<button
					class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm hover:bg-gray-600 focus:outline-none hover:cursor-pointer"
					title="Refresh Provider locations, education, residencies, certifications, societies and fellowships"
					@click="refreshData"
				>
					<DatabaseIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
					Refresh Provider Data
				</button>

				<a
					:href="frontendUrl + draft.path + '?preview=' + draft.preview_key"
					target="draft"
					class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 focus:outline-none hover:cursor-pointer"
				>
					<ExternalLinkIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
					Preview Draft
				</a>

				<a
					:href="frontendUrl + page.path"
					target="published"
					class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 focus:outline-none hover:cursor-pointer"
					:class="[page.live ? '' : 'opacity-50 pointer-events-none']"
				>
					<ExternalLinkIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
					View Page
				</a>
			</div>
		</div>

		<div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
			<aside class="px-2 py-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
				<nav class="space-y-1">
					<button
						v-for="(item, index) in navigation"
						:key="item.name"
						:class="[
							item.current
								? 'bg-gray-200 text-sc-blue hover:text-sc-blue hover:bg-gray-200'
								: 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
							'group min-w-full rounded-md px-3 py-2 flex items-center text-sm font-medium focus:outline-none',
						]"
						:aria-current="item.current ? 'page' : undefined"
						@click.prevent="changeTab(index)"
					>
						<font-awesome-icon
							v-if="iconType(item) === 'fa'"
							:icon="item.faIcon"
							:class="[
								item.current
									? 'text-sc-blue group-hover:text-sc-blue'
									: 'text-gray-400 group-hover:text-gray-500',
								'flex-shrink-0 mr-3 h-6 w-6',
							]"
							aria-hidden="true"
						/>

						<component
							:is="item.icon"
							v-if="iconType(item) === 'hero'"
							:class="[
								item.current
									? 'text-sc-blue group-hover:text-sc-blue'
									: 'text-gray-400 group-hover:text-gray-500',
								'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
							]"
							aria-hidden="true"
						/>
						<span class="truncate">
							{{ item.name }}
						</span>
					</button>
				</nav>
			</aside>

			<div class="sm:px-6 lg:px-0 lg:col-span-10">
				<MdStaffProviderGeneralInformation v-if="provider && currentTab == 0" @updatedPage="fetchAll" />
				<MdStaffProviderGallery v-if="provider && currentTab == 1" />
				<MdStaffProviderLocations v-if="provider && currentTab == 2" />
				<MdStaffProviderEducation v-if="provider && currentTab == 3" />
				<MdStaffProviderResidencies v-if="provider && currentTab == 4" />
				<MdStaffProviderCertifications v-if="provider && currentTab == 5" />
				<MdStaffProviderSocieties v-if="provider && currentTab == 6" />
				<MdStaffProviderBadges v-if="provider && currentTab == 7" />
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import getEnv from '@/extensions/env'

import {
	AcademicCapIcon,
	UserCircleIcon,
	UserGroupIcon,
	HeartIcon,
	FilmIcon,
	PhotographIcon,
	OfficeBuildingIcon,
	StarIcon,
	InformationCircleIcon,
	ExternalLinkIcon,
	DatabaseIcon,
	BadgeCheckIcon,
} from '@heroicons/vue/outline'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { useRoute } from 'vue-router'
import { provide, reactive, computed, ref } from 'vue'

library.add(faTags)

export default {
	components: {
		ExternalLinkIcon,
		DatabaseIcon,
		'font-awesome-icon': FontAwesomeIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const provider = reactive({})

		const page = ref(null)
		const draft = ref(null)
		const original = ref(null)
		const badges = ref(null)

		provide('badges', badges)

		provide(
			'provider',
			computed(() => provider.value)
		)

		return {
			page,
			draft,
			original,
			provider,
			api,
			toast,
			route,
			badges,
		}
	},
	data() {
		return {
			id: this.$route.params.id,
			record: {},
			currentTab: 0,
			navigation: [
				{
					name: 'General Information',
					icon: UserCircleIcon,
					current: true,
				},
				{
					name: 'Gallery',
					icon: PhotographIcon,
					current: false,
				},
				{
					name: 'Locations',
					icon: OfficeBuildingIcon,
					current: false,
				},
				{
					name: 'Education',
					icon: AcademicCapIcon,
					current: false,
				},
				{
					name: 'Residencies',
					icon: UserGroupIcon,
					current: false,
				},
				{
					name: 'Certifications',
					icon: HeartIcon,
					current: false,
				},
				{
					name: 'Societies',
					icon: StarIcon,
					current: false,
				},
				{
					name: 'Badges',
					icon: BadgeCheckIcon,
					current: false,
				},
			],
			// page: null,
			frontendUrl: getEnv('VUE_APP_FRONTEND_URL'),
		}
	},
	mounted() {
		this.fetchProvider()
		this.fetchPage()
		this.fetchBadges()
	},
	methods: {
		fetchAll() {
			this.fetchProvider()
			this.fetchPage()
			this.fetchBadges()
		},

		fetchProvider() {
			this.loaderShow()

			this.api.providers
				.fetch(this.route.params.id)
				.then(provider => {
					this.provider.value = provider
				})
				.catch(error => {
					this.toast.error('Error fetching provider:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		fetchBadges() {
			this.loaderShow()

			this.api.badges
				.all()
				.then(badges => {
					this.badges = badges
					console.log('Badges: ', this.badges)
				})
				.catch(error => {
					this.toast.error('Error fetching badges:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		setPage(page) {
			this.draft = null
			this.page = null
			this.original = null

			setTimeout(() => {
				if (page.draft_page) {
					this.draft = page.draft_page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))
					delete this.page.page_draft
				} else {
					this.draft = page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))
				}

				// console.log(this.draft)
			}, 100)
		},

		fetchPage() {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.pages.fetch()
				.then(page => {
					this.setPage(page)
					// if (Object.keys(page).length !== 0) this.page = page
				})
				.then(() => {
					this.loaderHide()
				})
		},

		refreshData() {
			this.loaderShow()

			this.api.mdstaff
				.providers(this.provider.value.id)
				.then(response => {
					this.toast.success('Successfully queued job to refresh MD-Staff data')
				})
				.catch(error => {
					this.toast.error('Error refreshing MD-Staff data:\n' + error)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		cancelEditing() {
			this.fetchProvider()
		},

		changeTab(index) {
			this.navigation.forEach(element => {
				element.current = false
			})

			this.navigation[index].current = true
			this.currentTab = index
		},

		iconType(item) {
			if (typeof item.icon !== 'undefined') {
				return 'hero'
			} else if (typeof item.faIcon !== 'undefined') {
				return 'fa'
			}
		},
	},
}
</script>
